import { useRef } from "react";
import {
  Box,
  IconButton,
  Typography,
  Autocomplete,
  TextField,
  Button,
  Tooltip,
} from "../../../components";
import { useState } from "react";
import {
  responseErros,
  timeoutBuscaAutocomplete,
  userLogs,
} from "../../../utils";
import { ProdutosService, notification } from "../../../services";
import { calcularPrecoProduto } from "../../../utils";
import MenuAddActions from "./menu-add-actions.component";
import store from "../../../config/store";

export default function CabecalhoPdv({
  listaProdutos,
  setListaProdutos,
  dadosUsuario,
  handleLogout,
  produto,
  setProduto,
  permitirVendaEstoqueNegativo,
  unidadeList,
  origensMercadoriasList,
  setItem,
  setUserLog,
  handleAdicionarProduto,
  setOpenImportarPedidoDialog,
  limparPdvAtalho,
}) {
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = "primary-add-actions-menu";
  const produtoService = new ProdutosService();
  const inputProdutoRef = useRef();

  const onInputChangeProdutos = (event) => {
    if (
      event.key === "Enter" &&
      inputProdutoRef.current === document.activeElement
    ) {
      let eventValue = event.target?.value;
      let isCodeBar = false;
      const isQuantidadeFind = eventValue.includes("*");
      if (isQuantidadeFind) {
        const value = eventValue.split("*");
        const filtros = {
          codigo: value[1],
          descricao: value[1],
        };
        if (
          Number.isFinite(parseFloat(eventValue)) &&
          value[1]?.length >= 8 &&
          value[1]?.length <= 13
        ) {
          filtros.codigoBarra = value[1];
          isCodeBar = true;
        }
        setLoadingAutoComplete(true);
        timeoutBuscaAutocomplete(
          buscarProdutos(filtros, parseFloat(value[0]), isCodeBar)
        );
      } else {
        const filtros = {
          codigo: eventValue,
          descricao: eventValue,
        };
        if (
          Number.isFinite(parseFloat(eventValue)) &&
          eventValue?.length >= 8 &&
          eventValue?.length <= 13
        ) {
          filtros.codigoBarra = eventValue;
          isCodeBar = true;
        }
        setLoadingAutoComplete(true);
        timeoutBuscaAutocomplete(buscarProdutos(filtros, null, isCodeBar));
      }
    }
  };

  const buscarProdutos = (filtros, quantidadeFind, isCodeBar = false) => {
    const filtro = {
      ...filtros,
      orderBy: "codigo",
      order: "asc",
      ativado: true,
      limite: 20,
    };
    produtoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setLoadingAutoComplete(false);
        setListaProdutos(result.data.rows);
        if (result.data.rows?.length === 1 && isCodeBar) {
          handleChangeAutocompleteProduto(
            result.data.rows[0],
            isCodeBar,
            quantidadeFind
          );
        }
      } else {
        setLoadingAutoComplete(false);
        responseErros(result);
      }
    });
  };

  function verificaEstoque(produto) {
    if (!permitirVendaEstoqueNegativo) {
      const estoque = produto?.estoqueProduto?.estoque;
      if (!estoque || estoque < 0) {
        notification.alertaGenericos("Produto fora de estoque");
        setProduto(null);
        return true;
      }
    }
    return false;
  }

  const handleChangeAutocompleteProduto = (
    produto,
    isBuscaCodBar = false,
    quantidadeFind
  ) => {
    document.getElementById("produtoId").blur();
    const estoque = verificaEstoque(produto);
    if (estoque) return;
    if (produto) {
      const itemTemp = {
        ...produto,
        produtoId: produto.id,
        valor: calcularPrecoProduto(produto, produto?.precoVenda, [], null),
        estoqueAtual: produto?.estoqueProduto?.estoque
          ? `${produto.estoqueProduto.estoque}`
          : 0,
        unidade: unidadeList.find(
          (item) => item.id === produto?.unidadeComercialVendaId
        )?.codigo,
        tributosIcmsOrigem: origensMercadoriasList.find(
          (item) => item.id === produto.origemMercadoriaId
        )?.codigo,
        quantidade: quantidadeFind ? quantidadeFind : 1,
        codigo: produto?.codigo,
      };
      setProduto(produto);
      setItem(itemTemp);
      if (isBuscaCodBar) {
        handleAdicionarProduto(produto, itemTemp);
      }
      userLogs(produto).then((logs) => {
        setUserLog(logs);
      });
    } else {
      setProduto(null);
      setItem(null);
    }
  };

  const handleAddActionsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box className="cabecalho-pdv">
      <Typography variant="h5" sx={{ fontWeight: 800 }} className="d-flex">
        SIAF
        <span style={{ fontWeight: 500 }}>LITE</span>
        PDV
        <span className="fw-light ps-1" style={{ fontSize: "12px" }}>
          v{store.version}
        </span>
      </Typography>
      <div className="w-100 d-flex align-items-center justify-content-between gap-4">
        <Autocomplete
          id="produtoId"
          name="produtoId"
          className="mousetrap"
          options={listaProdutos}
          autoHighlight
          loading={loadingAutoComplete}
          loadingText="Carregando"
          noOptionsText="Digite e pressione Enter"
          renderOption={(props, option) => {
            let descricaoProduto = `${option?.codigo} - ${option?.descricao}`;
            return option ? (
              <li {...props}>
                <div className="d-flex justify-content-between w-100">
                  <div>{descricaoProduto}</div>
                  <div>{option?.estoqueProduto?.estoque}</div>
                </div>
              </li>
            ) : (
              ""
            );
          }}
          getOptionLabel={(option) => {
            let descricaoProduto = `${option?.codigo} - ${option?.descricao}`;
            return option ? descricaoProduto : "";
          }}
          filterSelectedOptions
          onChange={(_, newValue) => handleChangeAutocompleteProduto(newValue)}
          value={produto}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={"outlined"}
              className="mousetrap"
              placeholder="Pesquise produtos pela descrição"
              onKeyDown={(e) => onInputChangeProdutos(e)}
              inputRef={inputProdutoRef}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
        <Button
          onClick={() => setOpenImportarPedidoDialog(true)}
          variant="outlined"
          color="padrao"
          sx={{
            p: "1rem 2rem",
            ml: "0.5em",
            color: "#494c62",
            fontSize: 12,
            fontWeight: 700,
          }}
        >
          Importar
        </Button>
        <Tooltip title="Limpar Carrinho" arrow placement="bottom">
          <Button
            id="limparPdv"
            variant="outlined"
            color="padrao"
            onClick={limparPdvAtalho}
            sx={{
              p: "1rem 2rem",
              fontSize: 20,
              boxShadow: "none",
              fontWeight: 700,
              height: "54px",
              width: "54px",
            }}
          >
            <i
              className="ph-fill ph-paint-brush-broad"
              style={{ transform: "rotate(180deg)" }}
            ></i>
          </Button>
        </Tooltip>
      </div>
      <div className="d-flex align-items-center justify-content-end gap-4">
        <div className="d-flex-max">
          <i
            className="ph-fill ph-user-circle"
            style={{ fontSize: 20, marginRight: 10 }}
          ></i>
          <Typography>{dadosUsuario ? dadosUsuario.nome : ""}</Typography>
        </div>
        {/*<Button onClick={() => setOpenDialogOperacoesCaixa(true)}>
          <div className="d-flex-max">
            <i
              className="ph-fill ph-shopping-bag-open"
              style={{ fontSize: 20, marginRight: 10 }}
            ></i>
            <Typography>
              Caixa
            </Typography>
          </div>
        </Button>*/}
        <IconButton
          aria-label="add action caixa"
          aria-controls={menuId}
          aria-haspopup="true"
          aria-expanded={isMenuOpen ? "true" : undefined}
          onClick={handleAddActionsOpen}
          style={{
            backgroundColor: "#E5E5E5",
          }}
        >
          <i
            className="ph-bold ph-dots-three-vertical"
            style={{ fontSize: 23 }}
          ></i>
        </IconButton>
        <IconButton
          onClick={() => {
            handleLogout();
          }}
          style={{
            padding: 12,
            borderRadius: 12,
            backgroundColor: "#e44a42",
          }}
        >
          <i
            className="ph-bold ph-sign-out"
            style={{ fontSize: 19, color: "white" }}
          ></i>
        </IconButton>
      </div>
      <MenuAddActions anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </Box>
  );
}
