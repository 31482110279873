import { useState } from "react";
import {
  Dialog,
  DialogContent,
  CardContent,
  CardActions,
  Step,
  StepLabel,
  Stepper,
  Button,
} from "../../../../components";
import {
  emailRecuperarSenhaValidator,
  alterarSenhaValidator,
} from "../../middlewares";
import {
  notification,
  LoginService,
  MeuUsuarioService,
} from "../../../../services";
import { responseErros } from "../../../../utils";
import { StorageHelper, InputErros } from "../../../../helpers";
import SteapCodigoRecuperacao from "./steap-condigo-recuperacao";
import SteapIdentificacao from "./steap-identificacao";
import SteapNovaSenha from "./steap-nova-senha";

const RecuperarSenhaDialogo = ({
  abrirDialogoRecuperarSenha,
  setAbrirDialogoRecuperarSenha,
  setLoading,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [inputErroEmail, setInputErroEmail] = useState(false);
  const [codigoExpirado, setCodigoExpirado] = useState(false);
  const [codigoRecuperacao, setCodigoRecuperacao] = useState(null);
  const [senha, setSenha] = useState({});
  const [email, setEmail] = useState("");
  const steps = ["Identificação", "Código de recuperação", "Nova senha"];
  const [inputErro, setInputErro] = useState([]);
  const [senhaScore, setSenhaScore] = useState();
  const inputErros = new InputErros(inputErro, setInputErro);
  const loginService = new LoginService();
  const storageHelper = new StorageHelper();
  const meuUsuarioService = new MeuUsuarioService();

  const retornaStep = () => {
    switch (activeStep) {
      case 1:
        return (
          <SteapCodigoRecuperacao
            codigoExpirado={codigoExpirado}
            setCodigoExpirado={setCodigoExpirado}
            setCodigoRecuperacao={setCodigoRecuperacao}
          />
        );
      case 2:
        return (
          <SteapNovaSenha
            senha={senha}
            setSenha={setSenha}
            inputErros={inputErros}
            setSenhaScore={setSenhaScore}
          />
        );
      default:
        return (
          <SteapIdentificacao
            inputErroEmail={inputErroEmail}
            setEmail={setEmail}
          />
        );
    }
  };

  const gerarCodigoRecuperacao = async () => {
    setLoading(true);
    const res = await loginService.gerarCodigoRecuperacaoSenha(email);
    setLoading(false);
    if (!res.isAxiosError) {
      setActiveStep(1);
    } else {
      notification.erroInternoServidor();
      fecharDialogo();
    }
  };

  const validarEmail = () => {
    if (inputErroEmail) {
      setInputErroEmail(false);
    }
    emailRecuperarSenhaValidator
      .validate(email, { abortEarly: false })
      .then(() => gerarCodigoRecuperacao())
      .catch((err) => {
        setInputErroEmail(true);
        notification.erroValidacao(err.message);
      });
  };

  const validarCodigoRecuperacao = async () => {
    setLoading(true);
    const res = await loginService.validarCodigoRecuperacaoSenha(
      email,
      codigoRecuperacao
    );
    setLoading(false);
    if (!res.isAxiosError) {
      storageHelper.setLocal("token", res.data.token);
      setActiveStep(2);
    } else {
      if (res.response && res.response.status == 400) {
        notification.alertaApi(res.response.data.message);
      } else {
        responseErros(res);
      }
    }
  };

  const alterarSenha = () => {
    alterarSenhaValidator
      .validate(senha, { abortEarly: false })
      .then(async () => {
        if (senhaScore < 2) {
          return notification.alertaGenericos("Senha fraca");
        }
        if (senha.novaSenha != senha.confirmarNovaSenha) {
          return notification.alertaGenericos(
            "Confirmar senha deve ser igual a senha"
          );
        }
        setLoading(true);
        const body = {
          senha: senha.novaSenha,
          confirmarSenha: senha.confirmarNovaSenha,
        };
        const result = await meuUsuarioService.atualizar(body);
        setLoading(false);
        if (!result.isAxiosError) {
          notification.alteracaoSucesso();
          fecharDialogo();
        } else {
          responseErros(result);
        }
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const avancarStep = () => {
    switch (activeStep) {
      case 0:
        validarEmail();
        break;
      case 1:
        validarCodigoRecuperacao();
        break;
      case 2:
        alterarSenha();
        break;
    }
  };

  const fecharDialogo = () => {
    setActiveStep(0);
    setCodigoExpirado(false);
    setCodigoRecuperacao(null);
    setSenha({});
    setEmail("");
    setAbrirDialogoRecuperarSenha(false);
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      open={abrirDialogoRecuperarSenha}
    >
      <DialogContent>
        <CardContent>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {retornaStep()}
        </CardContent>
        <CardActions>
          <Button
            onClick={() => fecharDialogo()}
            variant="contained"
            color="padrao"
            size="large"
            margin="normal"
          >
            cancelar
          </Button>
          <Button
            onClick={() => avancarStep()}
            variant="contained"
            color="primary"
            disabled={codigoExpirado}
            size="large"
            margin="normal"
          >
            {activeStep == 2 ? "salvar" : "proximo"}
          </Button>
        </CardActions>
      </DialogContent>
    </Dialog>
  );
};

export default RecuperarSenhaDialogo;
