import { useRef, useEffect } from "react";

const useBeforeUnload = (func) => {
  const currentRef = useRef(func);

  useEffect(() => {
    currentRef.current = func;
  }, [func]);

  useEffect(() => {
    const beforeunload = (...args) => currentRef.current?.(...args);
    window.addEventListener("beforeunload", beforeunload);
    return () => window.removeEventListener("beforeunload", beforeunload);
  }, []);
};

export default useBeforeUnload;
